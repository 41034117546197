@mixin bg-bar($spacing: $space-base, $background: $color-primary, $easing: 0.4s $ease) {
  background: $background;
  content: '';
  height: 100vh;
  left: -$spacing;
  opacity: 0;
  padding: $spacing;
  position: absolute;
  transform: translate3d(0, -50%, 0) scaleY(0);
  transition: opacity $easing, visibility $easing, transform $easing;
  top: 50%;
  visibility: hidden;
  width: 100vw;
  z-index: 0;
}
