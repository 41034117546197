/* Skip links. */
.m-skip-links {
  left: $gutter-base;
  margin-top: $space-small;
  position: fixed;
  z-index: 100;

  &__link {
    left: 0;
    margin-left: -1234rem;
    position: absolute;
    top: 0;
    white-space: nowrap;

    &:focus {
      margin-left: 0;
    }
  }
}
