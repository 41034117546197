/* Form. */
.m-form {

  /* Form group. */
  &__group {
    margin: 0 0 $space-small;

    &:not(.m-form__group--is-horizontal):last-child {
      margin-bottom: 0;
    }

    &--is-horizontal {
      display: flex;
      flex-wrap: wrap;
      margin: ($space-small / 2 * -1) ($space-small / 2 * -1) $space-small / 2;
    }
  }

  /* Form element. */
  &__element {
    margin: 0 0 $space-base;

    &:last-child {
      margin-bottom: 0;
    }

    &--details {
      padding: 0;
    }

    .m-form__group & {
      margin-bottom: $space-small / 2;
    }

    .m-form__group--is-horizontal > & {
      flex: 0 1 auto;
      margin-bottom: 0;
      padding: $space-small / 2;
    }
  }

  /* Form label. */
  &__label {
    display: block;
    font-weight: $font-weight-bold;
    margin: 0 0 $space-small;
    padding: 0;
    width: 100%;

    &--fieldset {
      color: $color-default;
      font-family: $font-family-heading;
      font-size: $font-size-h2;
      font-weight: $font-weight-bold;
      line-height: $line-height-small;
      margin: $space-small 0 $space-base;
    }

    &--details {
      margin: 0;
      padding: $space-base $space-base $space-base calc(#{$font-size-base} + #{$space-base});
      position: relative;

      &::-webkit-details-marker {
        display: none;
      }

      &::before {
        background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$color-default}' d='M15.88 9.29L12 13.17 8.12 9.29a1 1 0 1 0-1.41 1.41l4.59 4.59a1 1 0 0 0 1.41 0l4.59-4.59a1 1 0 0 0 0-1.41 1 1 0 0 0-1.42 0z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        content: '';
        display: block;
        height: ($font-size-base * 1.5);
        left: $space-small;
        position: absolute;
        top: calc(#{$space-base} - .1rem);
        transform: rotate(-90deg);
        transition: transform .3s $ease;
        width: ($font-size-base * 1.5);
      }

      .m-form__element--details[open] > & {
        &::before {
          transform: rotate(0);
        }
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .m-form__group--is-horizontal > & {
      padding: 0 ($space-small / 2);

      @include breakpoint-up(tiny) {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
        padding: calc(#{$space-small} + #{($space-small / 2)} + 1px) ($space-small / 2) ($space-small / 2);

        &--checkboxes,
        &--radios {
          padding-top: ($space-small / 2);
        }
      }
    }
  }

  /* Form field. */
  &__field {
    height: 100%;
    margin: 0 0 $space-small;
    width: 100%;

    &--details {
      display: none;
      padding: $space-base;

      .m-form__element--details[open] & {
        display: block;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    .m-form__group--is-horizontal > & {
      padding: 0 ($space-small / 2);

      @include breakpoint-up(tiny) {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
        padding: ($space-small / 2);
      }
    }
  }

  /* Form control. */
  &__control {
    display: flex;
    margin: 0 0 $space-small;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  /* Form input. */
  &__input {
    .m-form__control--has-error &:not([type="radio"]):not([type="checkbox"]) {
      border-color: $color-danger;
    }

    .m-form__control--has-addon &:not(:first-child):not([type="radio"]):not([type="checkbox"]) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      flex: 1 1 0%;
    }

    .m-form__control--has-addon &:not(:last-child):not([type="radio"]):not([type="checkbox"]) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      flex: 1 1 0%;
    }
  }

  /* Form description. */
  &__description {
    color: tint($color-default, 30%);
    font-size: $font-size-small;
    line-height: $line-height-small;
    margin: 0 0 $space-small;

    &:last-child {
      margin-bottom: 0;
    }
  }

  /* Form errors. */
  &__errors {
    color: $color-danger;
    font-size: $font-size-small;
    line-height: $line-height-small;
    margin: 0 0 $space-small;

    &:last-child {
      margin-bottom: 0;
    }
  }

  /* Form addon. */
  &__addon {
    background-color: tint($color-default, 90%);
    border-top: 1px solid $color-default-border;
    border-bottom: 1px solid $color-default-border;
    color: $color-default;
    flex: 0 0 auto;
    font-weight: $font-weight-bold;
    padding: $space-base;

    &--prefix {
      border-left: 1px solid $color-default-border;
      border-radius: $border-radius 0 0 $border-radius;
    }

    &--suffix {
      border-right: 1px solid $color-default-border;
      border-radius: 0 $border-radius $border-radius 0;
    }

    .m-form__control--has-error & {
      background-color: tint($color-default, 90%);
      border-color: $color-danger;
      color: $color-danger;
    }
  }

  /* Select. */
  &__control--select {
    & select {
      appearance: none;
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{tint($color-default, 50%)}' d='M15.88 9.29L12 13.17 8.12 9.29a1 1 0 1 0-1.41 1.41l4.59 4.59a1 1 0 0 0 1.41 0l4.59-4.59a1 1 0 0 0 0-1.41 1 1 0 0 0-1.42 0z'/%3E%3C/svg%3E");
      background-position: calc(100% - #{$space-small}) center;
      background-repeat: no-repeat;
      background-size: ($font-size-base * 1.5);
      height: auto;
      padding-right: calc(#{$font-size-base * 1.5} + #{$space-base});
    }
  }

  /* Checkbox and radio. */
  &__control--checkbox,
  &__control--radio {
    position: relative;

    & input {
      position: absolute;
      visibility: hidden;
    }

    & input + label {
      font-weight: $font-weight-base;
      padding: 0 0 0 calc(#{$font-size-base * 1.125} + #{$space-base * .25});
      position: relative;

      .m-form__group--is-horizontal & {
        margin-right: $space-small;
      }
    }

    & input + label::before {
      background-color: white;
      border: 1px solid $color-default-border;
      border-radius: $border-radius;
      content: '';
      display: block;
      flex: 0 0 auto;
      height: ($font-size-base * 1.125);
      left: 0;
      line-height: ($font-size-base * 1.125);
      position: absolute;
      top: 1px;
      width: ($font-size-base * 1.125);
    }

    & input[type="radio"] + label::before {
      border-radius: 50%;
    }

    & input:checked + label::before {
      background-color: $color-primary;
      background-position: center center;
      background-repeat: no-repeat;
      border-color: $color-primary;
    }

    & input[type="checkbox"]:checked + label::before {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' d='M9.612 15.688l9.04-9.04 1.2 1.2-10.24 10.24-4.76-4.76 1.2-1.2z'/%3E%3C/svg%3E");
    }

    & input[type="radio"]:checked + label::before {
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'%3E%3Ccircle fill='white' cx='12' cy='12' r='4'/%3E%3C/svg%3E");
    }
  }

}