/* Button. */
@mixin button(
  $bg: $color-primary,
  $border-color: $color-primary,
  $color: white,
  $hover-bg: shade($color-primary, 30%),
  $hover-border-color: shade($color-primary, 30%),
  $hover-color: white
) {
  background: $bg;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  color: $color;
  cursor: pointer;
  display: inline-block;
  font-size: $font-size-base;
  font-weight: $font-weight-bold;
  line-height: $line-height-small;
  margin: 0;
  outline: none;
  padding: $space-base;
  text-decoration: none;
  vertical-align: middle;

  &:hover,
  &:focus,
  &:active {
    background: $hover-bg;
    border-color: $hover-border-color;
    color: $hover-color;
  }

  &:focus,
  &:active {
    box-shadow: inset 0 1px 5px rgba(black, .1);
  }

  &:focus {
    outline: $outline;
    outline-color: $border-color;
    outline-offset: 2px;
  }

  &:disabled {
    cursor: not-allowed;
    filter: grayscale(100%);
    opacity: .2;
    pointer-events: none;
  }
}
