/* Font family */
$font-family-default: 'CoreSans', sans-serif;
$font-family-heading: 'FFMarkWebPro', sans-serif;

/* Font size */
$font-size-min: 16px;
$font-size-max: 16px;
$font-size-base: 1rem;
$font-size-small: ($font-size-base * 0.875); // 14px
$font-size-large: ($font-size-base * 1.25); // 20px
$font-size-huge: ($font-size-base * 1.5); // 24px
$font-size-extra-huge: ($font-size-base * 1.875); // 24px

$font-size-h1: ($font-size-base * 4.75); // 76px
$font-size-h2: ($font-size-base * 3.5); // 56px
$font-size-h3: ($font-size-base * 2.5); // 40px
$font-size-h4: ($font-size-base * 2); // 32px
$font-size-h5: ($font-size-base * 1.5); // 24px
$font-size-h6: ($font-size-base * 1.25); // 20px

/* Font weight */
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi-bold: 600;
$font-weight-bold: 700;
$font-weight-base: $font-weight-normal;

/* Line height */
$line-height-base: 1;
$line-height-small: ($line-height-base * 1.125);
$line-height-medium: ($line-height-base * 1.25);
$line-height-large: ($line-height-base * 1.5);
$line-height-huge: ($line-height-base * 1.75);

/* Color */
$color-default: #7d9394;
$color-default-border: tint($color-default, 70%);

$color-primary: #001c36;
$color-primary-shade: #0c6f85;
$color-primary-border: tint($color-primary, 70%);
$color-primary-dark: shade($color-primary, 40%);
$color-primary-extra-dark: shade($color-primary, 60%);

$color-secondary: #00a196;
$color-secondary-shade: #075263;
$color-secondary-light: tint($color-secondary, 70%);
$color-secondary-extra-light: tint($color-secondary, 90%);
$color-secondary-dark: shade($color-secondary, 40%);

$color-tertiary: #df3a30;
$color-quaternary: #c78e00;

$color-highlight: #009adb;

$color-line: rgba(#696969, 0.15);
$color-link: $color-highlight;
$color-light: #ebf2f2;
$color-gray: #949494;

$color-success: #6abb4f;
$color-danger: #e41c33;
$color-warning: #f7b239;

$color-bitbucket: #2557cc;
$color-drupal: $color-highlight;
$color-react: #07b2bd;
$color-laravel: $color-tertiary;
$color-go: #009cde;

$color-case: #22817b;

$color-tynaarlo-primary: #539692;
$color-tynaarlo-secondary: #a5c351;
$color-tynaarlo-tertiary: #e5f0f4;
$color-tynaarlo-quaternary: #73c7c2;

$color-signify-primary: #00a196;
$color-signify-secondary: #5baea9;
$color-signify-tertiary: #002229;

$color-nedmag-primary: #00335a;
$color-nedmag-secondary: #00a0a9;

$color-reducept-primary: #41186d;
$color-reducept-secondary: #d8ebec;

$color-p4ss-primary: #edf8fc;

/* Border radius */
$border-radius: 3px;

/* Space */
$space-base: 1rem;
$space-tiny: ($space-base * 0.25);
$space-small: ($space-base * 0.5);
$space-medium: ($space-base * 1);
$space-large: ($space-base * 1.5);
$space-huge: ($space-base * 2);
$space-giant: ($space-base * 2.5);
$space-container: ($space-huge * 3);

/* Width */
$width-tiny: 560px;
$width-small: 768px;
$width-medium: 992px;
$width-large: 1200px;
$width-huge: 1440px;
$width-giant: 1800px;
$width-giantplus: 2000px;
$width-mega: 2200px;
$width-insane: 2560px;
$width-base: $width-giant;

/* Gutter */
$gutter-tiny: $space-tiny;
$gutter-small: $space-small;
$gutter-medium: $space-base;
$gutter-large: $space-huge;
$gutter-base: $gutter-medium;

/* Breakpoints. */
$breakpoints: (
  nano: 0px,
  tiny: $width-tiny,
  small: $width-small,
  medium: $width-medium,
  large: $width-large,
  huge: $width-huge,
  giant: $width-giant,
  giantplus: $width-giantplus,
  mega: $width-mega,
  insane: $width-insane,
);

/* Layout */
$layout-width: $width-base;
$layout-columns: 24;

/* Outlines */
$outline: none;
