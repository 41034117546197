/* Icon. */
@mixin icon($width: calc(#{$font-size-base} * 1.5), $height: $width) {
  display: inline-flex;
  height: $height;
  width: $width;

  & svg {
    display: block;
    fill: currentColor;
    height: inherit;
    width: inherit;
  }
}
