/* Button. */
.m-button {
  @include button;

  /* Sizes. */
  &--small {
    font-size: $font-size-small;
    padding: $space-small;
  }

  &--large {
    font-size: $font-size-large;
    padding: ($space-base * 1.5);
  }

  /* White. */
  &--is-white {
    background-color: white;
    border-color: transparent;
    color: $color-primary;

    &:hover,
    &:focus,
    &:active {
      background-color: rgba(white, .8);
      border-color: transparent;
      color: $color-primary;
    }

    &:focus {
      outline-color: white;
    }
  }
}
