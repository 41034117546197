/* Logo. */
.m-logo {
  display: block;
  height: auto;
  max-width: 170px;
  position: relative;
  width: 100%;

  & img {
    aspect-ratio: auto;
    will-change: transform;
  }

  &:not(.m-logo--no-hover) {
    padding: $space-huge;

    @include breakpoint-down(small) {
      padding: $space-huge / 2;
    }
  }

  &:not(.m-logo--no-hover):not(.m-logo--arrow) {
    @include breakpoint-up(medium) {
      max-width: 280px;
    }
  }

  &.m-logo--arrow {
    @include breakpoint-down(medium) {
      padding: $space-large 0;
    }
  }

  @include breakpoint-up(medium) {
    &--no-hover img {
      max-width: 200px;
    }
  }

  &:before {
    background: $color-highlight;
    content: '';
    height: 100%;
    max-height: 0;
    left: 0;
    bottom: 0;
    transform: translate3d(0, 20px, 0);
    transition: opacity 0.2s $ease, max-height 0.2s $ease, transform 0.2s $ease, visibility 0.2s $ease;
    opacity: 0;
    position: absolute;
    pointer-events: none;
    visibility: hidden;
    width: 100%;
    will-change: transform;
  }

  &[href*='drupal'] {
    &:before {
      background: $color-drupal;

      @include breakpoint-down(medium) {
        background: transparent;
      }
    }
  }

  &[href*='laravel'] {
    &:before {
      background: $color-laravel;

      @include breakpoint-down(medium) {
        background: transparent;
      }
    }
  }

  &[href*='react'] {
    &:before {
      background: $color-react;

      @include breakpoint-down(medium) {
        background: transparent;
      }
    }
  }

  &[href*='go'] {
    &:before {
      background: $color-go;

      @include breakpoint-down(medium) {
        background: transparent;
      }
    }

    & .m-logo__image {
      margin-left: $space-base;
      max-width: 160px;

      @include breakpoint-down(medium) {
        max-width: 130px;
        margin-left: unset;
      }

      @include breakpoint-down(tiny) {
        max-width: 90px;
      }
    }
  }

  @include breakpoint-up(large) {
    &:not(.m-logo--no-hover):hover {
      img,
      div {
        transform: scale(0.85);
      }

      &:before {
        opacity: 1;
        transform: translate3d(0, 0, 0);
        visibility: visible;
        max-height: calc(100% + #{$space-base} * 4);
      }
    }
  }

  @include breakpoint-down(medium) {
    .h-stack > &:not(&--no-hover) {
      margin: 0 auto;
      gap: 10px;
    }
  }

  &--arrow {
    @include breakpoint-down(medium) {
      display: flex;
      max-width: 100%;
      justify-content: space-between;
      padding: $space-large 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.3);
      align-items: center;

      &:last-child {
        border-bottom: none;
      }

      & .m-card__arrow {
        transform: translate3d(-2.2rem, 0, 0);
        height: fit-content;

        &::before {
          background-color: white;
        }

        & > svg {
          height: 18px;
          width: auto;
        }
      }

      &:hover {
        & .m-card__arrow {
          &::before {
            opacity: 1;
            transform: translate3d(1rem, 0, 0) scaleY(1);
            visibility: visible;
          }

          & > svg {
            color: $color-secondary;
            transform: translate3d(1.1rem, 0, 0);
          }
        }
      }
    }

    @include breakpoint-up(large) {
      max-width: 280px;
    }

    & > .m-logo__image {
      @include breakpoint-down(medium) {
        max-width: 160px;
      }

      @include breakpoint-down(tiny) {
        max-width: 120px;
      }
    }
  }

  &__image {
    filter: drop-shadow(0 0 4px transparent);
    height: 100%;
    object-fit: contain;
    transition: filter 0.2s $ease, transform 0.2s $ease;
    width: 100%;
    will-change: transform;

    & svg {
      max-width: 100%;
    }
  }

  &__max-width {
    &--150 {
      max-width: 150px;
    }

    &--280 {
      max-width: 280px;
    }
  }
}
