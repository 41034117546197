.h-align--center {
  display: flex;
  align-items: center;
}

.h-align-self--center {
  align-self: center;
}

.h-align--landing-page {
  @include breakpoint-down(small) {
    & > :not(.m-card):not(.h-image) {
      padding: var(--layout-space) 1.5rem;
    }

    & > .h-image {
      padding: var(--layout-space) 0;
      margin-top: 0 !important;
    }
  }
}

.h-align--bottom {
  position: absolute;
  bottom: 0;

  &-from-large {
    @include breakpoint-up(large) {
      position: absolute;
      bottom: 0;
    }
  }

  &-flex {
    display: flex;
    align-items: flex-end;
  }
}

.h-align--case-contact {
  @include breakpoint-up(large) {
    bottom: calc(12rem - var(--layout-space));
    position: absolute;
  }
}
