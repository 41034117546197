/* Layout. */
.t-layout {
  position: relative;
  width: 100%;

  /* Spacing. */
  &--inset-collapse {
    padding-top: 0;
    padding-bottom: 0;
  }

  &--inset-small {
    padding-top: $space-small;
    padding-bottom: $space-small;
  }

  &--inset-large {
    padding-top: $space-large;
    padding-bottom: $space-large;
  }

  &--inset-huge {
    padding-top: $space-huge;
    padding-bottom: $space-huge;
  }

  &--inset-container {
    padding-top: $space-container * 0.25;
    padding-bottom: $space-container * 0.25;

    @include breakpoint-up(small) {
      padding-top: $space-container * 0.5;
      padding-bottom: $space-container * 0.5;
    }

    @include breakpoint-up(medium) {
      padding-top: $space-container * 0.75;
      padding-bottom: $space-container * 0.75;
    }

    @include breakpoint-up(large) {
      padding-top: $space-container;
      padding-bottom: $space-container;
    }

    &.u-spacing-py--collapse-from-medium {
      @include breakpoint-up(large) {
        .m-card-clip--bg {
          &:before {
            height: calc(100% + #{$space-container} * 2);
            margin-top: $space-container * -1;
          }

          &:after {
            background: inherit;
            content: '';
            height: calc(100% + #{$space-container} * 2);
            width: 100%;
            margin-top: $space-container * -1;
            position: absolute;
            top: 0;
            left: 0;
          }
        }
      }
    }

    &-at-medium {
      @include breakpoint-down(medium) {
        padding-top: $space-container * 0.75;
        padding-bottom: $space-container * 0.75;
      }

      @include breakpoint-down(small) {
        padding-top: $space-container * 0.5;
        padding-bottom: $space-container * 0.5;
      }

      @include breakpoint-down(tiny) {
        padding-top: $space-container * 0.25;
        padding-bottom: $space-container * 0.25;
      }
    }
  }

  &--inset-collapse-on-small {
    @include breakpoint-down(small) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  &--offset-top {
    @include breakpoint-up(medium) {
      &:before {
        background: white;
        content: '';
        left: 0;
        height: $space-container;
        position: absolute;
        width: 100%;
        z-index: 2;
      }

      &.t-layout--theme-dark {
        &:before {
          background: $color-primary;
        }
      }

      &.t-layout--offset-top-white {
        &:before {
          background: white;
        }
      }
    }
  }

  &--img-bg {
    &--nedmag {
      @include breakpoint-up(large) {
        display: flex;
        align-items: flex-end;

        & .t-grid__item {
          margin-top: $space-container;
        }
      }
    }

    &--tynaarlo {
      @include breakpoint-up(large) {
        display: flex;
        align-items: flex-start;
        min-height: 60vh;
      }
    }
  }

  /* Primary. */
  &--is-primary {
    background-color: $color-primary;
    color: white;

    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      color: white;
    }

    & p,
    a {
      color: white;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: rgba(white, 0.6);
        text-decoration: none;
      }
    }
  }

  &--carousel {
    padding-top: $space-container;
    padding-bottom: $space-container;

    @include breakpoint-down(small) {
      padding-top: 0;
      overflow: hidden;
    }

    @include breakpoint-up(large) {
      overflow: hidden;
    }
  }

  /* Themes */
  &--theme-dark {
    background-color: $color-primary;
    color: white;
  }

  &--theme-light {
    background-color: $color-light;
  }

  &--theme-gray {
    background-color: $color-gray;
  }

  &--theme-secondary {
    background-color: $color-secondary;
    color: white;

    .t-lines {
      &__line {
        background: white;
      }
    }

    & .o-header__arrow {
      &:before {
        background: white;
      }

      &:hover {
        color: $color-secondary;
      }
    }
  }

  &--theme-signify-flare {
    color: white;

    @include breakpoint-up(large) {
      &:before {
        content: '';
        background: linear-gradient($color-signify-tertiary 0%, rgba($color-signify-tertiary, 0) 100%);
        height: 10%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        z-index: 10;
      }
    }

    & > .m-parallax {
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 1;
    }

    & * {
      z-index: 2;
    }
  }

  &--theme-secondary-light {
    background-color: $color-secondary-extra-light;
  }

  &--theme-tertiary {
    background-color: $color-tertiary;
    color: white;
  }

  &--theme-quaternary {
    background-color: $color-quaternary;
  }

  &--theme-highlight {
    background-color: $color-highlight;
  }

  &--theme-black {
    background-color: black;
    color: white;

    & .t-lines__line {
      background: white;
      opacity: 0.3;
    }
  }

  &--theme-white {
    background-color: white;
    color: $color-primary;

    & .h-ornament svg {
      fill: black;

      & path {
        fill: black;
      }

      & g {
        fill: black;
      }
    }
  }

  &--theme-bitbucket {
    background-color: $color-bitbucket;
    color: #fff;

    & .o-header__text--highlighted::before {
      background-color: #001c36;
    }

    & .o-header__arrow {
      &:before {
        background: white;
      }

      &:hover {
        color: $color-bitbucket;
      }
    }
  }

  &--theme-drupal {
    background-color: $color-drupal;
    color: white;
    z-index: 4;

    & .t-lines {
      z-index: 5;

      &__line {
        background: rgba(255, 255, 255, 0.15);
      }
    }

    & .o-header__arrow {
      &:before {
        background: white;
      }

      &:hover {
        color: $color-drupal;
      }
    }

    & a:active,
    & a:focus {
      color: white;
    }
  }

  &--theme-laravel {
    background-color: $color-laravel;
    color: white;
    z-index: 4;

    & .t-lines {
      z-index: 5;

      &__line {
        background: rgba(255, 255, 255, 0.15);
      }
    }

    & .o-header__arrow:before,
    & .m-card__arrow:before {
      background: darken($color-laravel, 10%);
    }

    & a:active,
    & a:focus {
      color: white;
    }
  }

  &--theme-react {
    background-color: $color-react;
    color: white;
    z-index: 4;

    & .t-lines {
      z-index: 5;

      &__line {
        background: rgba(255, 255, 255, 0.15);
      }
    }

    & .o-header__arrow {
      &:before {
        background: white;
      }

      &:hover {
        color: $color-react;
      }
    }

    & .m-card__arrow {
      &:before {
        background: white;
      }

      &:hover {
        color: $color-react;
      }
    }

    & a:active,
    & a:focus {
      color: white;
    }
  }

  &--theme-go {
    background-color: $color-go;
    color: white;
    z-index: 4;

    & .t-lines {
      z-index: 5;

      &__line {
        background: rgba(255, 255, 255, 0.15);
      }
    }

    & .o-header__arrow {
      &:before {
        background: white;
      }

      &:hover {
        color: $color-go;
      }
    }

    & .m-card__arrow {
      &:before {
        background: white;
      }

      &:hover {
        color: $color-go;
      }
    }

    & a:active,
    & a:focus {
      color: white;
    }
  }

  &--theme-tynaarlo {
    &-primary {
      background-color: $color-tynaarlo-primary;
      color: white;
    }

    &-secondary {
      background-color: $color-tynaarlo-secondary;
      color: $color-primary;
    }

    &-white {
      background-color: white;
      color: $color-primary;
    }

    &-home {
      background-color: $color-tynaarlo-secondary;
      color: white;

      &::before {
        content: '';
        background: linear-gradient(360deg, #000000, transparent);
        left: 0;
        bottom: 0;
        height: 60%;
        position: absolute;
        mix-blend-mode: darken;
        top: unset;
        width: 100%;
        z-index: 3;
      }

      & .t-grid {
        @include breakpoint-up(large) {
          grid-template-rows: minmax(60vh, auto) auto;
        }

        @include breakpoint-up(giantplus) {
          grid-template-rows: minmax(70vh, auto) auto;
        }
      }
    }
  }

  &--theme-nedmag {
    &-home {
      background-color: $color-nedmag-primary;
      color: white;
      overflow: visible;

      &::before {
        content: '';
        background: linear-gradient(360deg, $color-nedmag-primary, transparent);
        left: 0;
        bottom: 0;
        height: 50%;
        position: absolute;
        mix-blend-mode: darken;
        top: unset;
        width: 100%;
        z-index: 2;
      }

      @include breakpoint-up(medium) {
        & .o-header__content {
          width: 100%;
        }

        & .o-header__title {
          position: absolute;
          transform: translate3d(0, -2rem, 0);
        }
      }

      @include breakpoint-up(mega) {
        & .o-header__title {
          transform: translate3d(0, -6rem, 0);
        }
      }
    }

    &-primary {
      background-color: $color-nedmag-primary;
      color: white;
    }
  }

  &--theme-reducept {
    &-primary {
      background-color: $color-reducept-primary;
      color: white;

      & .o-header__bg {
        &:before {
          content: none;
        }
      }
    }

    &-secondary {
      background-color: $color-reducept-secondary;
      color: $color-case;
    }
  }

  &--theme-p4ss {
    background-color: $color-primary;
    color: white;

    &::before {
      content: '';
      background: linear-gradient(180deg, rgba(0, 28, 54, 0) 0%, $color-primary 90%);
      left: 0;
      bottom: 0;
      height: 100%;
      position: absolute;
      top: unset;
      width: 100%;
      z-index: 2;
    }
  }

  &.t-layout--line-overlap {
    & > .t-lines {
      z-index: 2;
    }
  }

  &--overlap-above {
    top: -100%;
  }
}
