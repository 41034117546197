/* Heading */
h1,
h2,
h3,
h4,
h5,
h6 {
  &:not(.ignore-heading-styles) {
    font-family: $font-family-heading;
    font-weight: $font-weight-bold;
    line-height: $line-height-medium;
    overflow-wrap: break-word;
    word-wrap: break-word;

    @include breakpoint-up(small) {
      overflow-wrap: normal;
      word-wrap: normal;
    }
  }

  &.ignore-heading-styles {
    font-weight: normal;

    & a {
      text-decoration: none;

      &:hover {
        color: currentColor;
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: inherit;
  margin: 0;
}

h1:not(.ignore-heading-styles) {
  font-size: 1.75rem;

  @include breakpoint-up(tiny) {
    font-size: 2.125rem;
  }

  @include breakpoint-up(small) {
    font-size: 2.5rem;
  }

  @include breakpoint-up(medium) {
    font-size: 3.5rem;
  }

  @include breakpoint-up(large) {
    font-size: $font-size-h1;
  }
}

h2:not(.ignore-heading-styles) {
  font-size: 1.5rem;

  @include breakpoint-up(tiny) {
    font-size: 1.875rem;
  }

  @include breakpoint-up(small) {
    font-size: 2.125rem;
  }

  @include breakpoint-up(medium) {
    font-size: 2.875rem;
  }

  @include breakpoint-up(large) {
    font-size: $font-size-h2;
  }
}

h3:not(.ignore-heading-styles) {
  font-size: 1.25rem;

  @include breakpoint-up(tiny) {
    font-size: 1.625rem;
  }

  @include breakpoint-up(small) {
    font-size: 1.75rem;
  }

  @include breakpoint-up(medium) {
    font-size: 2.25rem;
  }

  @include breakpoint-up(large) {
    font-size: $font-size-h3;
  }
}

h4:not(.ignore-heading-styles) {
  font-size: 1.125rem;

  @include breakpoint-up(tiny) {
    font-size: 1.375rem;
  }

  @include breakpoint-up(small) {
    font-size: 1.625rem;
  }

  @include breakpoint-up(medium) {
    font-size: 1.875rem;
  }

  @include breakpoint-up(large) {
    font-size: $font-size-h4;
  }
}

h5:not(.ignore-heading-styles) {
  font-size: 1rem;

  @include breakpoint-up(tiny) {
    font-size: 1.125rem;
  }

  @include breakpoint-up(small) {
    font-size: 1.75rem;
  }

  @include breakpoint-up(medium) {
    font-size: 2rem;
  }

  @include breakpoint-up(large) {
    font-size: $font-size-h4;
  }
}

h6:not(.ignore-heading-styles) {
  font-size: 1rem;

  @include breakpoint-up(tiny) {
    font-size: 1.125rem;
  }

  @include breakpoint-up(small) {
    font-size: 1.75rem;
  }

  @include breakpoint-up(medium) {
    font-size: 2rem;
  }

  @include breakpoint-up(large) {
    font-size: $font-size-h4;
  }
}

/* Paragraph */
p {
  font-size: $font-size-large;
  line-height: 1.65;
  margin: 0;

  & a {
    color: $color-highlight;
    display: inline-flex;
    position: relative;

    &:before {
      @include bg-bar($spacing: 0, $background: $color-highlight, $easing: 0.2s $ease);
      left: -$space-tiny;
      top: -$space-tiny;
      max-width: calc(100% + #{$space-tiny} * 2);
      max-height: calc(100% + #{$space-tiny} * 2);
      transform: scaleY(0);
      transform-origin: bottom;
      z-index: -1;
    }

    &:hover {
      color: white;
      text-decoration-color: transparent;

      &:before {
        opacity: 1;
        transform: scaleY(1);
        visibility: visible;
      }
    }
  }
}

/* Hyperlink */
a {
  color: inherit;
  cursor: pointer;
  line-height: $line-height-medium;
  text-decoration: underline currentColor;
  text-decoration-skip-ink: auto;
  transition: color 0.2s $ease, text-decoration-color 0.2s $ease;

  &:hover,
  &:focus,
  &:active {
    color: $color-highlight;
    text-decoration-color: transparent;
  }

  &:focus {
    outline: $outline;
    outline-offset: 2px;
  }

  &.u-text-color--highlight {
    &:hover {
      color: white !important;
    }
  }

  .m-card--theme-highlight:not(.m-card--link) & {
    position: relative;

    &:before {
      @include bg-bar($spacing: 0, $background: white, $easing: 0.2s $ease);
      left: -$space-tiny;
      top: -0.75 * $space-tiny;
      max-width: calc(100% + #{$space-tiny} * 2);
      max-height: calc(100% + #{$space-tiny} * 2);
      transform: scaleY(0);
      transform-origin: bottom;
      z-index: -1;
    }

    &:hover {
      color: $color-highlight;
      text-decoration-color: transparent;

      &:before {
        opacity: 1;
        transform: scaleY(1);
        visibility: visible;
      }
    }
  }

  .m-card--theme-white:not(.m-card--link) &,
  .m-card--theme-transparent & {
    position: relative;

    &:before {
      @include bg-bar($spacing: 0, $background: $color-highlight, $easing: 0.2s $ease);
      left: -$space-tiny;
      top: -0.75 * $space-tiny;
      max-width: calc(100% + #{$space-tiny} * 2);
      max-height: calc(100% + #{$space-tiny} * 2);
      transform: scaleY(0);
      transform-origin: bottom;
      z-index: -1;
    }

    &:hover {
      color: white;
      text-decoration-color: transparent;

      &:before {
        opacity: 1;
        transform: scaleY(1);
        visibility: visible;
      }
    }
  }
}

/* Blockquote */
blockquote {
  margin: 0;
}

/* Figure */
figure {
  margin: 0;
}

/* Image */
img {
  aspect-ratio: 16 / 9;
  image-rendering: -webkit-optimize-contrast;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
}

picture {
  height: 100%;
  width: 100%;

  & img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

/* Subscript & Superscript */
sup,
sub {
  font-size: $font-size-small;
}

/* Address */
address {
  font-style: normal;
}

/* Line */
hr {
  border: none;
  border-bottom: 1px solid $color-default-border;
  height: 1px;
  margin: 0;
}

/* List */
ul,
ol {
  margin: 0;
}
ul {
  list-style-type: disc;
  padding-inline-start: 18px;

  li {
    font-size: inherit;
  }
}
ol {
  list-style-type: decimal;
}

/* Small */
small {
  font-size: $font-size-small;
}

/* Strong */
strong {
  font-weight: $font-weight-bold;
}

/* Break rules */
br {
  display: none;

  @include breakpoint-up(medium) {
    display: block;
  }
}

body.blendMode {
  cursor: url(/images/cursor-small.png) 4 5, default;

  & * {
    font-family: 'Comic Sans MS', sans-serif !important;
  }
}

#blendMode {
  background: none;
  bottom: 0;
  border: none;
  height: 10px;
  left: 0;
  position: fixed;
  width: 30px;
}
