/* Header. */
.o-header {
  display: flex;
  overflow: hidden;
  padding-top: 7rem;
  padding-bottom: 1.5 * $space-huge;
  position: relative;

  @include breakpoint-up(medium) {
    padding: $space-container * 2.25 0;
  }

  &__icon {
    order: 1;
    margin-right: $space-base;
  }

  &--full {
    @include breakpoint-up(medium) {
      min-height: 100vh;
      padding: 4rem 0;
    }

    & .o-header__content {
      height: auto;

      @include breakpoint-up(medium) {
        min-height: calc(100vh - 8rem);
      }
    }

    & .o-header__title {
      margin-top: $space-base;

      @include breakpoint-up(medium) {
        margin-top: $space-huge;
      }
    }

    & .o-header__text {
      margin-bottom: $space-base * 2;

      @include breakpoint-up(medium) {
        margin-bottom: $space-base * 6;
      }
    }

    & .o-header__arrow {
      margin-top: 0;
    }

    &-overlap {
      @include breakpoint-up(medium) {
        min-height: calc(100vh + #{$space-container * 0.75});
      }

      @include breakpoint-up(large) {
        min-height: calc(100vh + #{$space-container});
      }
    }
  }

  &__tag {
    background: rgba(white, 0.1);
    font-size: $font-size-large;
    font-weight: 500;
    margin-right: auto;
    margin-bottom: $space-huge;
    padding: $space-base;
  }

  &__title {
    display: inline-flex;
    flex-direction: column;
    line-height: 1.1;

    &-text {
      order: 2;
    }

    & > span {
      margin-top: auto;
    }

    @include breakpoint-up(small) {
      margin: ($space-huge * 1.5) 0;
    }

    @include breakpoint-up(medium) {
      margin-bottom: 0;
      margin-top: auto;

      &--cases {
        padding-bottom: 4 * $space-huge;
      }
    }

    @include breakpoint-up(medium) {
      &--cases {
        padding-bottom: 6 * $space-huge;
      }
    }

    & > span > svg {
      margin-bottom: $space-large;
    }

    &--no-description {
      margin: auto 0;
    }
  }

  &__arrow {
    background: none;
    border-radius: 0;
    border: none;
    display: inline-flex;
    margin-right: auto;
    margin-top: $space-container * 0.5;
    padding: (0.75 * $space-base) (1.25 * $space-base);
    position: relative;

    @include breakpoint-up(medium) {
      margin-top: $space-container * 1.5;
    }

    svg {
      position: relative;
      transform: translate3d(-1.25 * $space-base, 0, 0);
      transition: transform 0.2s $ease;
      width: 20px;
      z-index: 20;
    }

    &:before {
      background: $color-highlight;
      content: '';
      height: 100%;
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 0;
      transform-origin: bottom;
      transform: translate3d(-1.25 * $space-base, 0, 0) scaleY(0);
      transition: opacity 0.2s $ease, transform 0.2s $ease, visibility 0.2s $ease;
      visibility: hidden;
      width: 100%;
      z-index: 10;
    }

    &:hover {
      color: currentColor;

      svg {
        transform: translate3d(0, 0, 0);
      }

      &:before {
        opacity: 1;
        transform: translate3d(0, 0, 0) scaleY(1);
        visibility: visible;
      }
    }

    @include breakpoint-up(medium) {
      margin-bottom: $space-huge * 2;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 6;

    &-wrapper {
      position: relative;
      z-index: 3;
    }

    &--landingpage {
      @include breakpoint-up(medium) {
        & .o-header__title {
          display: flex;
          flex-direction: row;
          margin-top: auto;

          &-text {
            max-width: 60%;
            order: 1;
          }
        }

        & .o-header__icon {
          display: inline-flex;
          margin-left: auto;
          order: 2;
          width: 100%;

          & > svg {
            width: 50%;
            margin-bottom: auto;
            margin-left: auto;
            margin-right: $space-huge;
          }
        }

        & .o-header__text {
          margin-top: $space-huge;
        }
      }

      @include breakpoint-down(medium) {
        & .o-header__icon svg {
          max-width: 30%;
          max-height: 30%;
          margin-bottom: $space-huge;
        }
      }
    }

    &--case {
      &-tynaarlo {
        & .o-header__arrow {
          &:hover {
            color: $color-tynaarlo-secondary;
          }

          &:before {
            background: white;
          }
        }

        @include breakpoint-up(small) {
          min-height: 70vh;

          & .t-grid {
            height: 100%;
          }
        }
      }

      &-nedmag {
        & .o-header__arrow {
          &:hover {
            color: $color-nedmag-primary;
          }

          &:before {
            background: white;
          }
        }
      }

      &-signify {
        & .o-header__arrow {
          &:hover {
            color: $color-signify-primary;
          }

          &:before {
            background: white;
          }
        }
      }

      @include breakpoint-up(medium) {
        & .o-header__title {
          display: flex;
          margin-top: auto;

          &-text {
            margin-top: $space-huge;
          }
        }

        & .o-header__icon {
          display: inline-flex;
          width: 50%;
          margin-bottom: auto;
          margin-top: auto;

          & > svg {
            width: 50%;
          }
        }

        & .o-header__text {
          margin-top: $space-huge;
        }

        & .o-header__arrow {
          margin-bottom: 0;
        }
      }

      @include breakpoint-up(large) {
        & .o-header__title {
          flex-direction: row;

          &-text {
            max-width: 60%;
            order: 1;
          }
        }

        &.o-header__icon {
          width: 100%;
          margin-right: 0;
          margin-left: auto;
        }
      }
      & .o-header__icon {
        .t-layout--theme-reducept-primary & {
          @include breakpoint-only(medium) {
            margin-top: 3rem;
          }
        }
      }

      @include breakpoint-down(medium) {
        & .o-header__icon svg {
          .t-layout--theme-tynaarlo-home &,
          .t-layout--theme-nedmag-home & {
            max-width: 30%;
            height: 100%;
          }

          .t-layout--theme-reducept-primary & {
            max-width: 20%;
            height: 100%;
          }
        }
      }

      @include breakpoint-down(small) {
        & .o-header__icon svg {
          height: auto;
          max-width: 30%;
          max-height: 30%;
          margin-bottom: var(--layout-space);

          .t-layout--theme-nedmag-home & {
            max-width: 25%;
          }

          .t-layout--theme-tynaarlo-home & {
            max-width: 20%;
          }
        }
      }
    }

    @include breakpoint-up(giant) {
      .t-layout--theme-reducept-primary & {
        & h1 {
          width: calc(100% + var(--layout-space));
        }
      }
    }
  }

  &__text {
    font-weight: 100;
    line-height: $line-height-large;
    font-family: $font-family-heading;
    margin: $space-huge 0;

    @include breakpoint-up(medium) {
      margin-top: $space-huge * 2;
      margin-bottom: auto;
    }

    &--highlighted {
      display: inline-block;
      margin-right: $space-base;
      margin-bottom: $space-base;
      position: relative;

      &:before {
        background: currentColor;
        content: '';
        height: calc(100% + #{$space-base});
        left: -$space-base;
        position: absolute;
        top: 55%;
        transform: translate3d(0, -50%, 0);
        width: calc(100% + (#{$space-base} * 2));
        z-index: -1;

        @include breakpoint-down(medium) {
          width: calc(100% + (#{$space-small} * 2));
          left: -$space-small;
        }
      }
    }

    &--vacancies {
      max-width: 680px;
    }
  }

  &__bg {
    height: 100%;
    left: 0;
    object-fit: cover;
    position: absolute;
    pointer-events: none;
    top: 0;
    width: 100%;
    z-index: 2;

    & img:not(.m-parallax__item):not(.o-header__bg--no-animation) {
      height: 100%;
      left: 0;
      object-fit: cover;
      position: absolute;
      top: 0;
      transform: scale(1.1);
      width: 100%;
      will-change: transform;

      &.o-header__bg--animate-right {
        opacity: 0;
        transform: translateX(10%);
      }

      .o-header--reveal & {
        opacity: 1;
        transition: transform 1s $ease, opacity 1s $ease;
        transform: scale(1) translate(0);
      }
    }

    &.o-header__bg--half {
      right: 0;
      max-width: 65%;
    }

    &:not(.o-header__bg--no-overlay):before {
      background: $color-primary;
      content: '';
      height: 100%;
      left: 0;
      mix-blend-mode: soft-light;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 10;
    }

    &--right {
      height: 100%;
      min-height: 100%;
      left: auto;
      transform: translate3d(40px, 0, 0);
      right: 0;

      & img:not(.m-parallax__item) {
        display: none;
        object-position: right;
        object-fit: contain;

        @include breakpoint-up(small) {
          display: block;
        }
      }

      &:before {
        display: none;
      }
    }

    &--left {
      height: 100%;
      left: 0;
      transform: translate3d(-40px, 0, 0);
      right: auto;
      width: 80%;

      & img {
        display: none;
        object-position: left;
        object-fit: fill;

        @include breakpoint-up(small) {
          display: block;
        }

        @include breakpoint-up(large) {
          object-fit: contain;
        }
      }

      &:before {
        display: none;
      }
    }
  }

  &.t-layout--theme-nedmag-home {
    overflow: visible;
  }

  &--text-centered {
    text-align: center;

    & .o-header__content {
      margin: 0 auto;
    }
  }

  &--service {
    & > .o-header__bg {
      @include breakpoint-down(medium) {
        &:after {
          content: '';
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          position: absolute;
          background-color: $color-primary;
          opacity: 0.5;
        }
      }

      & img {
        object-position: right;
        position: relative;
      }

      & .o-header__arrow {
        position: absolute;
        bottom: 0;
        left: 0;
        margin: 0;
      }
    }
  }
}
