/* Document. */
*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  @include responsive-type;
}

html {
  color: $color-default;
  font-family: $font-family-default;
  font-weight: $font-weight-base;
  height: 100%;
  line-height: $line-height-base;
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;

  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  height: 100%;
  margin: 0;
  overflow-x: hidden;
}

/* HTML 5 */
figcaption,
figure,
main {
  display: block;
}

nav ul {
  display: flex;
  flex-direction: column;
  list-style: none;
  padding-left: 0;

  & > * + * {
    margin-top: $space-base;
  }

  @include breakpoint-up(small) {
    flex-direction: row;

    & > * + * {
      margin-top: 0;
      margin-left: $space-base;
    }
  }
}
