/* Hide utilities. */
.u-hide {
  display: none !important;
}

/* Hide @only. */
.u-hide--from-tiny {
  @include breakpoint-only(tiny) {
    display: none !important;
  }
}
.u-hide--from-small {
  @include breakpoint-only(small) {
    display: none !important;
  }
}
.u-hide--from-medium {
  @include breakpoint-only(medium) {
    display: none !important;
  }
}
.u-hide--from-large {
  @include breakpoint-only(large) {
    display: none !important;
  }
}
.u-hide--from-huge {
  @include breakpoint-only(huge) {
    display: none !important;
  }
}

/* Hide @min. */
.u-hide--at-min-tiny {
  @include breakpoint-up(tiny) {
    display: none !important;
  }
}
.u-hide--at-min-small {
  @include breakpoint-up(small) {
    display: none !important;
  }
}
.u-hide--at-min-medium {
  @include breakpoint-up(medium) {
    display: none !important;
  }
}
.u-hide--at-min-large {
  @include breakpoint-up(large) {
    display: none !important;
  }
}
.u-hide--at-min-huge {
  @include breakpoint-up(huge) {
    display: none !important;
  }
}

/* Hide @max. */
.u-hide--at-max-tiny {
  @include breakpoint-down(tiny) {
    display: none !important;
  }
}
.u-hide--at-max-small {
  @include breakpoint-down(small) {
    display: none !important;
  }
}
.u-hide--at-max-medium {
  @include breakpoint-down(medium) {
    display: none !important;
  }
}
.u-hide--at-max-large {
  @include breakpoint-down(large) {
    display: none !important;
  }
}

/* Show utilities. */
.u-show {
  display: block !important;
}

/* Show @only. */
.u-show--from-tiny {
  display: none;

  @include breakpoint-down(tiny) {
    display: block !important;
  }
}
.u-show--from-small {
  display: none;

  @include breakpoint-only(small) {
    display: block !important;
  }
}
.u-show--from-medium {
  display: none;

  @include breakpoint-only(medium) {
    display: block !important;
  }
}
.u-show--from-large {
  display: none;

  @include breakpoint-only(large) {
    display: block !important;
  }
}
.u-show--from-huge {
  display: none;

  @include breakpoint-only(huge) {
    display: block !important;
  }
}

.u-display--flex {
  display: flex !important;
}

.u-direction-column {
  flex-direction: column !important;
}

.u-direction-column-reverse {
  flex-direction: column-reverse !important;
}

.u-direction-column--from-tiny {
  @include breakpoint-down(tiny) {
    flex-direction: column !important;
  }
}
.u-direction-column--from-small {
  @include breakpoint-down(small) {
    flex-direction: column !important;
    justify-content: space-evenly;
  }
}
.u-direction-column--from-medium {
  @include breakpoint-down(medium) {
    flex-direction: column !important;
  }
}
.u-direction-column--from-large {
  @include breakpoint-down(large) {
    flex-direction: column !important;
  }
}
.u-direction-column--from-huge {
  @include breakpoint-down(huge) {
    flex-direction: column !important;
  }
}

.u-show-above-footer {
  z-index: 11 !important;
}
