/* Responsive type. */
@mixin responsive-type(
  $min-font: $font-size-min,
  $max-font: $font-size-max,
  $min-width: $width-small,
  $max-width: $width-large
) {
  font-size: $min-font;

  @media (min-width: #{$min-width}) and (max-width: #{$max-width}) {
    font-size: calc(#{$min-font} + (#{strip-unit($max-font)} - #{strip-unit($min-font)}) * ((100vw - #{$min-width}) / (#{strip-unit($max-width)} - #{strip-unit($min-width)})));
  }

  @media (min-width: #{$max-width}) {
    font-size: $max-font;
  }
}
