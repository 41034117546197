/* Container. */
.t-container {
  margin-left: auto;
  margin-right: auto;
  max-width: $width-base;
  padding-left: calc(1.5 * var(--layout-space));
  padding-right: calc(1.5 * var(--layout-space));
  width: 100%;

  @include breakpoint-up(small) {
    padding-left: calc(2 * var(--layout-space));
    padding-right: calc(2 * var(--layout-space));
  }

  @include breakpoint-up(large) {
    padding-left: var(--layout-space);
    padding-right: var(--layout-space);
  }

  /* Fluid. */
  &--is-fluid {
    max-width: none;
  }

  /* Small. */
  &--is-small {
    max-width: $width-small;
  }

  &-on-small--inset-collapse {
    @include breakpoint-down(medium) {
      padding: 0;
    }
  }
}
