@font-face {
  font-family: 'CoreSans';
  src: url('../fonts/CoreSansD25Light.woff2') format('woff2'),
    url('../fonts/CoreSansD25Light.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CoreSans';
  src: url('../fonts/392098_0_0.woff2') format('woff2'),
    url('../fonts/392098_0_0.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'CoreSans';
  src: url('../fonts/392098_1_0.woff2') format('woff2'),
    url('../fonts/392098_1_0.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FFMarkWebPro';
  src: url('../fonts/MarkProLight.woff2') format('woff2'),
    url('../fonts/MarkProLight.woff') format('woff');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FFMarkWebPro';
  src: url('../fonts/392098_2_0.woff2') format('woff2'),
    url('../fonts/392098_2_0.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FFMarkWebPro';
  src: url('../fonts/392098_3_0.woff2') format('woff2'),
    url('../fonts/392098_3_0.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'FFMarkWebPro';
  src: url('../fonts/392098_4_0.woff2') format('woff2'),
    url('../fonts/392098_4_0.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
