/* Table */
table {
  border-collapse: collapse;
  border-spacing: 0;
  margin: $space-small 0 $space-base;
  width: 100%;
}
th,
td {
  empty-cells: show;
  font-weight: $font-weight-base;
  padding: $space-small;
  text-align: left;
}
th {
  font-weight: $font-weight-bold;
}
