:root {
  /* Default values for grid */
  --column-start: 1;
  --column-end: 12;
  --row-start: auto;
  --row-end: auto;
  --column-start-small: var(--column-start);
  --column-end-small: var(--column-end);
  --row-start-small: var(--row-start);
  --row-end-small: var(--row-end);
  --column-start-medium: var(--column-start-small);
  --column-end-medium: var(--column-end-small);
  --row-start-medium: var(--row-start-small);
  --row-end-medium: var(--row-end-small);
  --column-start-large: var(--column-start-medium);
  --column-end-large: var(--column-end-medium);
  --row-start-large: var(--row-start-medium);
  --row-end-large: var(--row-end-medium);
  --column-start-huge: var(--column-start-large);
  --column-end-huge: var(--column-end-large);
  --row-start-huge: var(--row-start-large);
  --row-end-huge: var(--row-end-large);

  /* Layout */
  --layout-space: calc((100vw - (#{$space-base} * 2)) / #{$layout-columns});
  --layout-content-width: calc(var(--layout-space) * #{$layout-columns});
  --layout-inset: calc(var(--layout-space) * 2);
  --layout-overlay: calc(var(--layout-space) * 6);

  @include breakpoint-up(small) {
    --layout-inset: calc(var(--layout-space) * 1.5);
    --layout-overlay: calc(var(--layout-space) * 4.5);
  }

  @include breakpoint-up(large) {
    --layout-content-width: calc(var(--layout-space) * #{$layout-columns - 2});
    --layout-inset: var(--layout-space);
    --layout-overlay: calc(var(--layout-space) * 3);
  }

  @include breakpoint-up(giant) {
    --layout-space: calc(
      (#{$layout-width} - (#{$space-base} * 2)) / #{$layout-columns}
    );
  }
}
