/* Lines */
.t-lines {
  bottom: 0;
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 1;
  display: none;

  .o-header & {
    z-index: 2;
  }

  &--display {
    @include breakpoint-up(large) {
      display: block;
    }
  }

  .t-lines--overlap-bottom &,
  .t-lines--overlap-bottom-from-large & {
    z-index: 3;

    @include breakpoint-up(medium) {
      height: calc(100% + calc(#{$space-container} * 0.75));
    }
    @include breakpoint-up(large) {
      height: calc(100% + calc(#{$space-container} * 1));
    }
  }

  .t-lines--overlap-bottom-double & {
    @include breakpoint-up(medium) {
      height: calc(100% + calc(#{$space-container} * 1.5));
    }
    @include breakpoint-up(large) {
      height: calc(100% + calc(#{$space-container} * 2));
    }
  }

  .m-navigation & {
    display: block;

    @include breakpoint-down(medium) {
      display: none;
    }
  }

  &__container {
    max-width: calc(#{$layout-width} - calc(var(--layout-space) * 2 * 1.5));
    height: 100%;
    left: 50%;
    position: relative;
    transform: translate3d(-50%, 0, 0);
    width: calc(100vw - calc(var(--layout-space) * 2 * 1.5));

    @include breakpoint-up(small) {
      max-width: calc(#{$layout-width} - calc(var(--layout-space) * 2));
      width: calc(100% - calc(var(--layout-space) * 2));
    }
  }

  &__line {
    background: black;
    bottom: 0;
    height: 100%;
    left: -0.1%;
    opacity: 0.12;
    position: absolute;
    top: 0;
    width: 1px;

    .m-navigation--is-visible &,
    .o-header & {
      transition: max-height 1.75s $ease;
    }

    .m-navigation &,
    .o-header & {
      :not(.m-navigation--is-visible) & {
        max-height: 0;

        &:nth-child(2) {
          transition-delay: 0;
        }

        &:nth-child(3) {
          transition-delay: 0;
        }

        &:last-child {
          transition-delay: 0;
        }
      }
    }

    .t-layout--theme-dark &,
    .h-color-block-primary &,
    .t-layout--theme-nedmag-primary &,
    .t-layout--theme-reducept-primary &,
    .t-layout--theme-signify-flare &,
    .t-layout--theme-nedmag-home & {
      background: white;
      opacity: 0.2;
    }

    .t-layout--theme-p4ss & {
      opacity: 0;
    }

    &:nth-child(2) {
      left: 50%;
      transition-delay: 0.25s;
    }

    &:nth-child(3) {
      display: none;
      transition-delay: 0.5s;
    }

    &:last-child {
      left: 99.9%;
      transition-delay: 0.75s;
    }

    @include breakpoint-up(large) {
      &:nth-child(2) {
        left: 33.3%;
      }

      &:nth-child(3) {
        display: block;
        left: 66.6%;
      }
    }
  }

  &_p4ss {
    .t-lines__line {
      &:nth-child(1) {
        height: calc(100% - #{$space-container});

        &::before {
          content: '';
          background: black;
          bottom: $space-container * -1;
          height: $space-container;
          left: -0.1%;
          opacity: 0.6;
          position: absolute;
          width: 1px;
        }
      }
    }
  }
}
