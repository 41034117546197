/* Icon */
.m-icon {
  @include icon;
  position: relative;

  &--centered {
    align-items: center;
    justify-content: center;
  }

  /* Sizes */
  &--small {
    height: $font-size-base;
    width: $font-size-base;
  }

  &--large {
    height: calc(#{$font-size-base} * 2);
    width: calc(#{$font-size-base} * 2);
  }

  &__quote {
    &--tynaarlo {
      @include breakpoint-down(small) {
        & > svg {
          width: 80%;
        }
      }
    }
  }

  &__search {
    @include breakpoint-down(small) {
      & > svg {
        width: 80%;
      }
    }
  }

  &__case {
    @include breakpoint-down(small) {
      max-width: 140px;
      & > svg {
        width: 100%;
      }
    }

    &--small {
      max-width: 160px;

      @include breakpoint-down(small) {
        max-width: 100px;
        & > svg {
          width: 100%;
        }
      }
    }
  }
}
