/* Grid. */
.t-grid {
  @include grid(12, $gutter: 0);

  @include breakpoint-up(medium) {
    @include grid(24, $gutter: 0);
  }

  &--auto {
    @include grid-auto();
  }

  &--row-fraction {
    grid-template-rows: 1fr;
  }

  &--row-reducept {
    @include breakpoint-up(large) {
      grid-template-rows: repeat(2, 1fr);
    }
  }

  &--column-gap-collapse {
    column-gap: 0;
    row-gap: 0;
  }

  &__gap {
    &-from-small {
      @include breakpoint-down(small) {
        gap: 4rem 0;
      }
    }
  }

  &__item {
    grid-column: 1 / 13;
    grid-row: auto;
    position: relative;
    width: 100%;

    &.u-display--flex {
      flex-direction: column;
    }

    @include breakpoint-down(small) {
      grid-column: var(--column-start-small) / calc(var(--column-end-small) + 1);
      grid-row: var(--row-start-small) / var(--row-end-small);
    }

    @include breakpoint-up(medium) {
      grid-column: var(--column-start-medium) / calc(var(--column-end-medium) + 1);
      grid-row: var(--row-start-medium) / var(--row-end-medium);
    }

    @include breakpoint-up(large) {
      grid-column: var(--column-start-large) / calc(var(--column-end-large) + 1);
      grid-row: var(--row-start-large) / var(--row-end-large);
    }

    @include breakpoint-up(huge) {
      grid-column: var(--column-start-huge) / calc(var(--column-end-huge) + 1);
      grid-row: var(--row-start-huge) / var(--row-end-huge);
    }

    @include breakpoint-up(insane) {
      grid-column: var(--column-start-insane) / calc(var(--column-end-insane) + 1);
      grid-row: var(--row-start-huge) / var(--row-end-huge);
    }
  }
}
