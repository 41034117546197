/* Fieldset */
fieldset {
  border: none;
  margin: $space-small 0 $space-base;
  padding: 0;
}

/* Details */
details {
  border: 1px solid $color-default-border;
  border-radius: $border-radius;
  margin: $space-small 0 $space-base;
  padding: $space-base;
}

/* Label, legend and summary */
label,
legend,
summary {
  cursor: pointer;
  font-weight: $font-weight-bold;
  font-style: normal;
  line-height: $line-height-small;
  margin: 0;
  padding: 0 0 $space-small;
}

/* Summary */
summary {
  margin: -$space-base;
  padding: $space-base;
}

/* Button */
button {
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: $line-height-base;
  cursor: pointer;
}

/* Form */
input[type='text'],
input[type='password'],
input[type='number'],
input[type='date'],
input[type='datetime'],
input[type='datetime-local'],
input[type='month'],
input[type='week'],
input[type='email'],
input[type='search'],
input[type='tel'],
input[type='time'],
input[type='url'],
textarea,
select {
  background-color: white;
  border: solid 1px $color-default-border;
  border-radius: $border-radius;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: $line-height-small;
  margin: 0;
  outline: none;
  padding: $space-base;
  width: 100%;

  &:focus {
    border-color: $color-primary;
  }

  &:disabled {
    cursor: not-allowed;
    filter: grayscale(100%);
    opacity: 0.3;
    pointer-events: none;
  }

  &::placeholder {
    color: tint($color-default, 50%);
    font-style: italic;
  }
}
textarea {
  max-width: none;
  resize: vertical;
}
select:not([multiple]) {
  height: calc(#{$space-base * 2} + #{$line-height-small}em + 2px);
}
