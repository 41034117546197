/* Breakpoint up. */
@mixin breakpoint-up($breakpoint) {
  $min: map-get($breakpoints, $breakpoint);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

/* Breakpoint down. */
@mixin breakpoint-down($breakpoint) {
  $max: map-get-next($breakpoints, $breakpoint);

  @if $max {
    @media (max-width: #{$max - 1}) {
      @content;
    }
  } @else {
    @content;
  }
}

/* Breakpoint only. */
@mixin breakpoint-only($breakpoint) {
  $min: map-get($breakpoints, $breakpoint);
  $max: map-get-next($breakpoints, $breakpoint);

  @if $min and $max {
    @media (min-width: $min) and (max-width: #{$max - 1}) {
      @content;
    }
  } @else if $max == null {
    @include breakpoint-up($breakpoint) {
      @content;
    }
  } @else {
    @content;
  }
}
