/* Wrapper. */
.t-wrapper {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
  opacity: 0;
  overflow: hidden;
  position: relative;
  transition: opacity 0.2s $ease, visibility 0.2s $ease;
  visibility: hidden;
  width: 100%;

  &--is-loaded {
    opacity: 1;
    visibility: visible;
  }

  &--scrollable-video {
    overflow: visible;
  }

  .u-overflow--locked & {
    overflow-y: hidden;
    position: relative;
    height: 100vh;
    max-height: 100vh;
    min-height: 100vh;
  }
}
